import { InvalidAmount, newAmount, type Amount } from '@orus.eu/amount'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { validateTime, type Time } from './time'

export type ValidationResult<VALUE> = { ok: true; value: VALUE } | { ok: false; hint: string }

export function validateInteger(text: string): ValidationResult<number> {
  text = text.trim().replace(/ /g, '')
  if (!text) {
    return { ok: false, hint: 'Ce champ est obligatoire' }
  }
  if (/^[0-9]+$/.test(text)) {
    return { ok: true, value: parseInt(text, 10) }
  }
  if (/^[0-9.,]+$/.test(text)) {
    return { ok: false, hint: 'Les décimales ne sont pas autorisées ici' }
  }
  return { ok: false, hint: "Ce champ n'accepte que des nombres entiers" }
}
export function validateAmount(text: string): ValidationResult<Amount> {
  text = text.trim().replace(/ /g, '').replace(/,/g, '.').replace(/€/g, '')
  if (!text) {
    return { ok: false, hint: 'Ce champ est obligatoire' }
  }
  try {
    return { ok: true, value: newAmount(text) }
  } catch (err) {
    if (err instanceof InvalidAmount) {
      return { ok: false, hint: 'Veuillez saisir un montant (maximum deux décimales)' }
    } else {
      throw err
    }
  }
}
export function validateRequiredField(text: string): ValidationResult<string> {
  text = text.trim()
  if (!text) {
    return { ok: false, hint: 'Ce champ est obligatoire' }
  }
  return { ok: true, value: text }
}

export function validateEmail(text: string): ValidationResult<string> {
  text = text.trim().toLowerCase()
  if (!text) {
    return { ok: false, hint: 'Ce champ est obligatoire' }
  }
  const indexOfAt = text.indexOf('@')
  if (indexOfAt < 1 || indexOfAt === text.length - 1) {
    return { ok: false, hint: 'Saisissez un email valide' }
  }
  const indexOfDot = text.indexOf('.', indexOfAt)
  if (indexOfDot < 1 || indexOfDot === text.length - 1) {
    return { ok: false, hint: 'Saisissez un email valide' }
  }
  return { ok: true, value: text }
}
export function validateTimeTextInput(text: string): ValidationResult<Time> {
  text = text.trim()
  if (!text) {
    return { ok: false, hint: 'Ce champ est obligatoire' }
  }
  const [hours, minutes] = text.split(':').map(Number)
  if (isNaN(hours) || isNaN(minutes)) {
    return { ok: false, hint: "Merci d'utiliser le format hh:mm" }
  }
  const validatedTime = validateTime({ hours, minutes })
  if (validatedTime === null) {
    return { ok: false, hint: "Merci d'utiliser le format hh:mm" }
  }
  return { ok: true, value: validatedTime }
} /**
 * Validate a phone number.
 *
 * @param {string} text
 * @returns {ValidationResult<string>}
 */

export function validatePhoneNumber(text: string): ValidationResult<string> {
  text = text.trim()
  if (!text) {
    return { ok: false, hint: 'Ce champ est obligatoire' }
  }

  if (!isValidPhoneNumber(text, 'FR')) {
    return { ok: false, hint: 'Ce numéro de téléphone ne semble pas correct' }
  }

  return { ok: true, value: text }
}
