import { getZonedDateTimeFromMillis } from '@orus.eu/calendar-date'
import { DateTime } from 'luxon'

export class InvalidTimeInputError extends Error {
  constructor(timestamp: number, zone: string) {
    super(`Invalid input timestamp=${timestamp}, zone=${zone}`)
  }
}

/**
 * Time represented on the 24h format. For example 13:00
 */
export type Time = {
  hours: number
  minutes: number
}

export function validateTime(time: Time): Time | null {
  if (
    Number.isInteger(time.hours) &&
    time.hours >= 0 &&
    time.hours < 24 &&
    Number.isInteger(time.minutes) &&
    time.minutes >= 0 &&
    time.minutes < 60
  ) {
    return time
  }
  return null
}

export function getTimeFromTimestamp(timestamp: number, zone: string): Time {
  const datetime = getZonedDateTimeFromMillis(timestamp, zone)
  if (!datetime.isValid) {
    throw new InvalidTimeInputError(timestamp, zone)
  }
  return {
    hours: datetime.hour,
    minutes: datetime.minute,
  }
}

export function formatTime(time: Time): string {
  const datetime = DateTime.fromObject({ hour: time.hours, minute: time.minutes })
  return datetime.toFormat('T')
}
